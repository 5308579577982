@charset "utf-8";
/*
------------------------------------------------------------
Project Name : Humehealth;
------------------------------------------------------------
*/

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Reset
--------------------------------------------------------------------------------------------------------*/

/*
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General
--------------------------------------------------------------------------------------------------------*/

* {
  outline: none !important;
}

html,
body {
  min-width: 320px;
  min-height: 100vh;
  background-color: rgba(68, 137, 121, 0.02);
}

body {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: subpixel-antialiased;
  scroll-behavior: smooth;
}

.has-loader{
	overflow-y: hidden !important;
}

.has-loader::before{
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.3);
	z-index: 91;
}

.overlay-open {
  overflow: hidden !important;
}

.overlay-open:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.33);
  z-index: 1;
}

.wrapper {
  min-height: 100vh;
  padding: 60px 60px 0 580px;
}

.wrapper.has-padding {
  padding: 60px 60px 0 120px;
}

.wrapper.subscription-wrapper {
  padding: 60px 580px 0 60px;
}

.inner-container {
  width: 1320px;
  max-width: 100%;
  margin: 0 auto;
}

a,
.custom-radio .custom-radio-layout {
  text-decoration: none;
  transition: all 0.5s;
}

a:hover,
a:focus {
  text-decoration: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 35px;
  margin: 0;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}

h1 span {
  font-weight: 400;
}

h3 span {
  font-weight: 300;
}

.form-group {
  margin-bottom: 41px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 40px;
}

label {
  color: #7C8393;
  font-size: 26px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 20px;
}

label.label-green {
  color: #448979;
  font-weight: 700;
}

.form-control {
  background-color: #fff;
  border: 3px solid #88CCBD;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(124, 130, 146, 0.101);
  height: 80px;
  color: #448979;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}

.form-control:focus {
  box-shadow: none;
  border-color: #448979;
  color: #448979;
  outline: none !important;
}

.form-control.secondary-input:focus,
.form-control.secondary-input:active {
  box-shadow: none;
  outline: #88CCBD !important;
  border-color: #88CCBD;
  color: #448979;
  background-color: #fff;
}

.form-control::placeholder {
  color: #448979;
  text-align: center !important;
}

.form-control.secondary-input {
  border: 0;
  border-bottom: 3px solid #88CCBD;
  box-shadow: 0 3px 6px rgba(124, 131, 147, 0.098);
  border-radius: 0;
  text-align: left;
  padding: 26px 27px;
  height: 80px;
  background-color: #F6FCFA;
}

.error-message p {
  color: #A60000;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 1.28px;
  line-height: 40px;
  font-weight: 400;
}

h1 {
  font-size: 30px;
  font-weight: 700;
  color: #448979;
}

h2,
.h2 {
  font-size: 26px;
  font-weight: 700;
  color: #7C8393;
}

h3 {
  font-size: 30px;
  font-weight: 700;
  color: #448979;
  line-height: normal;
}

h4 {
  font-size: 32px;
  font-weight: 700;
  color: #88CCBD;
  line-height: 40px;
  font-style: italic;
  letter-spacing: normal;
}

h5,
.h5 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #7C8393;
}

h6 {
  font-size: 21px;
  font-weight: 700;
  color: #7C8393;
  line-height: 30px;
}

p {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.text-primary {
  color: #448979 !important;
}

.btn-outline-success {
  background-color: #fff;
  border: 3px solid #88CCBD;
  border-radius: 0;
  width: 322px;
  height: 60px;
  color: #448979;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  padding: 19px 27px 17px 50px;
  position: relative;
}

.btn-outline-success:after {
  content: '';
  position: absolute;
  left: 20px;
  top: 50%;
  width: 24px;
  height: 24px;
  background-image: url("/assets/images/close-icon.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  transform: translateY(-50%);
}

.btn-outline-success:hover {
  background-color: #fff;
  color: #448979;
  border-color: #88CCBD;
}

.btn-outline-success:active {
  background-color: #fff;
  color: #448979;
  border-color: #88CCBD;
  box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
  background-color: #fff;
  color: #448979;
  border-color: #88CCBD;
}

.btn-outline-success.secondary {
  border-radius: 10px;
  width: 226px;
  padding: 14px 10px;
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  text-transform: none;
}

.btn-outline-success.secondary:after {
  content: none;
}

.btn-success {
  width: 226px;
  background-color: #88CCBD;
  box-shadow: 0 3px 6px rgba(124, 130, 146, 0.101);
  border-radius: 10px;
  height: 60px;
  padding: 17px 32px 18px;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  color: #FFFFFF;
  border: 0;
}

.btn-success:hover {
  background-color: #88CCBD;
  color: #fff;
}

.btn-success:focus {
  background-color: #88CCBD;
  color: #fff;
}

.btn-success:active {
  background-color: #88CCBD;
  color: #fff;
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
  background-color: #88CCBD;
  color: #fff;
}


.btn-primary {
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(124, 131, 147, 0.1);
  border-radius: 10px;
  width: 220px;
  height: 80px;
  text-align: right;
  font-size: 21px;
  font-weight: 700;
  color: #448979;
  line-height: 20px;
  padding: 25px 40px;
  border: 1px solid transparent;
  transition: all 0.5s;
  position: relative;
}

.btn-primary::after {
  content: '';
  position: absolute;
  left: 20px;
  top: 50%;
  width: 26px;
  height: 26px;
  background-image: url("../images/arrow.svg");
  background-repeat: no-repeat;
  background-size: 17px;
  transform: translate(0, -50%) rotate(180deg);
  transition: all 0.5s;
}

.btn-primary.no-icon {
  text-align: center;
  transition: all 0.5s;
}

.btn-primary.no-icon:hover {
  background-color: rgba(136, 204, 189, 0.1);
  border-color: transparent;
}

.btn-primary.no-icon::after {
  display: none;
}


.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #fff;
  color: #448979;
  border-color: transparent;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #fff;
  color: #448979;
  border-color: transparent;
  box-shadow: none !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #fff;
  border-color: #448979;
  color: #448979;
}

.btn-primary:hover::after,
.btn-primary:focus::after,
.btn-primary:active::after {
  transform: translate(10px, -50%) rotate(180deg);
}


.btn-primary.secondary {
  background-color: #448979;
  color: #fff;
  text-align: center;
}

.btn-primary.secondary:hover,
.btn-primary.secondary:focus,
.btn-primary.secondary:active,
.btn-primary.secondary:not(:disabled):not(.disabled).active,
.btn-primary.secondary:not(:disabled):not(.disabled):active,
.show > .btn-primary.secondary.dropdown-toggle {
  background-color: #284d44;
  color: #fff;
}

.btn-primary.arrow-right {
  text-align: left;
}

.btn-primary.secondary.arrow-right::after {
  left: auto;
  right: 32px;
  width: 16px;
  height: 25px;
  background-image: url("../images/arrow-white.svg");
  background-size: 16px;
  transform: translate(0, -50%) rotate(0deg);
}

.btn-primary.arrow-right::after {
  left: auto;
  right: 32px;
  width: 16px;
  height: 25px;
  background-image: url("../images/arrow.svg");
  background-size: 16px;
  transform: translate(0, -50%) rotate(0deg);
}

.btn-secondary {
  width: 280px;
  height: 80px;
  color: #fff;
  font-size: 30px;
  line-height: 32px;
  padding: 20px 10px;
  transition: all 0.5s;
  border: 0;
  background: linear-gradient(135deg, #448979 50%, #88CCBD);
  box-shadow: 0 4px 16px 0 rgba(53, 229, 188, 0.69);
  font-weight: 400;
}

.btn-secondary:focus,
.btn-secondary:active {
  box-shadow: none;
  border: 0;
  color: #fff;
  background: linear-gradient(135deg, #306156 50%, #5F8F84);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background: linear-gradient(135deg, #306156 50%, #5F8F84);
  color: #fff;
  border-color: transparent;
  box-shadow: none !important;
}

.btn-secondary:hover {
  background: linear-gradient(135deg, #306156 50%, #5F8F84);
}

.btn-secondary:disabled {
  background: #FAFAFA;
  color: rgba(124, 131, 147, 0.3);
  opacity: 1;
}

.card {
  background-color: #fff;
  border: 3px solid #88CCBD;
  box-shadow: 0px 5px 5px rgba(124, 131, 147, 0.101);
  border-radius: 10px;
  padding: 50px 0;
  transition: all 0.5s;
  cursor: pointer;
}

.card-body {
  padding: 0;
}

.card-body .card-title {
  margin: 0;
}

/* input-group */
.input-group.inside-label .input-group-prepend {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 5;
  background: transparent;
}

.input-group.inside-label .input-group-text {
  font-size: 21px;
  line-height: 25px;
  padding: 4px;
  color: #448979;
  background: transparent;
  border: none;
}

.input-group.inside-label > .form-control {
  border-radius: 10px !important;
  padding-left: 79px;
}

/* custom-radio */
.custom-radio {
  position: relative;
  display: inline-block;
}

.custom-radio .custom-radio-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.custom-radio .custom-radio-layout {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 3px solid #88CCBD;
  color: #448979;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  line-height: 40px;
}

.custom-radio .custom-radio-input:checked ~ .custom-radio-layout {
  color: #fff;
  background-color: #448979;
  border-color: #448979;
}


/* Main Content
--------------------------------------------------------------------------------------------------------*/

/* Home Sidebar css start */
aside {
  position: fixed;
  left: 0;
  top: 0;
  width: 480px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(124, 131, 147, 0.109);
  z-index: 9;
  padding-top: 120px;
  transition: all 0.3s;
}

.sidebar-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sidebar-main .brand {
  margin-bottom: 60px;
}

.sidebar-main .brand-detail {
  text-align: left;
  max-width: 190px;
  margin: 0 auto;
  white-space: nowrap;
  width: 100%;
}

.brand-detail .brand-title {
  margin-bottom: 29px;
}

.brand-detail .brand-address {
  color: #7C8393;
  font-size: 1.5rem;
}

.brand-address .text-strong {
  font-weight: 700;
  line-height: 29px;
}

.brand-address p {
  font-weight: 400;
  font-style: italic;
  line-height: 40px;
}

.brand-address p span {
  display: block;
}

/* Mobile menu button css start */
.mobile-header {
  display: none;
  margin-bottom: 29px;
}

.mobile-header .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-inner .mobile-brand a {
  display: inline-block;
}

.header-inner .mobile-brand img {
  max-width: 86px;
}

.header-inner .men-square {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(124, 131, 147, 0.098);
  position: relative;
  z-index: 2;
}

.header-inner .menu-icon {
  width: 25px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #448979;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}

.menu-icon span:nth-child(1) {
  top: 0px;
}

.menu-icon span:nth-child(2) {
  top: 8px;
}

.menu-icon span:nth-child(3) {
  top: 16px;
}

.menu-icon.menu-open span:nth-child(1) {
  top: 10px;
  transform: rotate(136deg);
}

.menu-icon.menu-open span:nth-child(2) {
  opacity: 0;
  right: -60px;
}

.menu-icon.menu-open span:nth-child(3) {
  top: 10px;
  transform: rotate(-136deg);
}

/* Home css satrt */

.has-padding .brand {
  width: 160px;
}

.home-block .home-box {
  background: transparent radial-gradient(closest-side at 50% 50%, #BCFFF8 0%, #8EB3E3 100%) 0% 0% no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 86px 0;
  opacity: 0.27;
  border: 13px solid rgba(255, 255, 255, 0.301);
  margin-top: 60px;
}

.home-box p {
  font-size: 40px;
  font-weight: 300;
  color: rgba(125, 125, 125, 0.17);
  letter-spacing: normal;
}

.home-title {
  margin-top: 40px;
  text-align: center;
  font-style: italic;
  display: none;
}

.home-title p {
  margin-top: 20px;
  font-size: 26px;
  font-weight: 400;
  color: #7C8393;
}

.card-block {
  margin-top: 40px;
  padding: 0 60px;
}

.card-block .card-inner {
  display: flex;
}

.card-wrapper {
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 25px;
}

.card-wrapper:last-child {
  margin-right: 0;
}

.card-label {
  width: 279px;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 40px;
  min-height: 115px;
}

.card-label p {
  color: #448979;
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
}


.card-wrapper .card img {
  max-width: 120px;
  margin: 0 auto;
}

.card-wrapper .card-body {
  margin-top: 21px;
}

.subscription::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.subscription::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

/* Handle */
.subscription::-webkit-scrollbar-thumb {
  background: #448979;
  border-radius: 5px;
}

.mobile-block {
  display: none;
}

.mobile-block .block-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
}

.block-inner .brand {
  width: 86px;
}

.block-inner .mobile-button a {
  width: 125px;
  padding: 18px 11px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(124, 131, 147, 0.098);
  border-radius: 10px;
  display: inline-block;
  color: #448979;
  font-size: 21px;
  font-weight: 700;
}

.block-inner .mobile-button .close-icon {
  padding-right: 11px;
}

.subscription-inner .subscribe-title {
  text-align: center;
}

.subscribe-title p {
  color: #448979;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 17px;
}

.subscription-inner .subscribe-service {
  margin-top: 20px;
}

.subscribe-service ul {
  margin: 0;
  padding: 0;
}

.subscribe-service li {
  margin-right: 0;
  margin-bottom: 20px;
}

.subscribe-service li .service-label,
.subscribe-service li .service-value {
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
}

.subscribe-service li .service-label {
  color: #7C8393;
}

.subscribe-service li .service-value {
  color: #448979;
}

.subscription-inner .subscribe-total {
  margin-top: 19px;
  text-align: center;
}

.small-title p {
  margin-top: 10px;
  font-size: 42px;
  font-weight: 700;
  color: #448979;
  line-height: 43px;
}

.subscription-inner .action-button {
  margin-top: 41px;
  text-align: center;
}

.subscribe-left .brand {
  width: 125px;
  margin-right: 33px;
}

.subscrib-hello h1 {
  font-weight: 400;
}

.subscrib-hello h1 span {
  font-weight: 700;
}

.subscribe-right .subscribe-menu {
  display: none;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(124, 131, 147, 0.098);
  border-radius: 10px;
  font-size: 28px;
  color: #448979;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.subscribe-menu .menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscribe-menu .menu-bar > * {
  margin: 20px 16px;
}


/* stepper */
.stepper-main {
  margin-top: 48px;
}

.stepper-inner ul {
  margin: 0 -37px;
  padding: 0;
  display: flex;
}

.stepper-inner li {
  position: relative;
  width: 33.33%;
  display: inline-block;
  padding: 0 37px;
  text-align: center;
}

.stepper-inner li .action-arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  width: 80px;
  text-align: center;
  display: none;
}

.action-arrow.arrow-left {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.arrow-left:disabled,
.arrow-right:disabled {
  opacity: 0.5;
}

.action-arrow.arrow-right {
  right: 0;
  transform: translateY(-50%);
}

.stepper-inner li:after {
  content: '';
  position: absolute;
  right: -17px;
  top: 50%;
  width: 34px;
  height: 3px;
  background-color: rgba(124, 131, 147, 0.15);
}

.stepper-inner li:last-child::after {
  content: none;
}

.stepper-inner li a {
  width: 280px;
  border-radius: 10px;
  background-color: rgba(250, 250, 250, 0);
  color: #88CCBD;
  font-size: 21px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  line-height: 25px;
  text-align: left;
}

.stepper-inner li a.step-active {
  color: #fff;
  background-color: #448979;
}

.stepper-inner li a.step-done {
  background-color: #fff;
  color: #448979;
  border: 3px solid #88CCBD;
  transition: all 0.5s;
}

.stepper-inner li a.step-done:hover {
  border-color: #fff;
}

.stepper-inner a .count-number,
.stepper-inner a .icon-done {
  width: 48px;
  height: 48px;
  min-width: 48px;
  line-height: 48px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: #88CCBD;
  color: #fff;
}

.stepper-inner a .count-number {
  font-size: 14px;
  font-weight: 500;
}

.stepper-inner a .name {
  display: inline-block;
  margin-left: 20px;
}

.stepper-inner a:not(.step-active):not(.step-done) .name {
  color: #88CCBD;
}

.stepper-inner a.step-active .count-number {
  background-color: #fff;
  color: #448979;
}

.stepper-inner a.step-done .count-number,
.stepper-inner a.step-done .icon-done {
  background-color: #448979;
  color: #fff;
}


.stepper-inner.secondary ul {
  flex-wrap: wrap;
}

.stepper-inner.secondary li {
  width: 50%;
  margin: 0;
  padding: 0 37px;
}

.stepper-inner.secondary li:first-child {
  text-align: right;
}

.stepper-inner.secondary li:last-child {
  text-align: left;
}


.stepper-inner.secondary a .name {
  text-align: left;
}

.subscription-main {
  margin-top: 75px;
}

.main-title {
  text-align: center;
  margin-bottom: 40px;
}

.main-title p {
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  color: #7C8393;
}

/* 2nd stepper css start */

.duration-main-block {
  text-align: center;
}

.duration-main-block .h2 {
  font-weight: 400;
  font-style: italic;
  padding-bottom: 20px;
}

.duration-main-block .duration-select-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.duration-main-block .duration-select-list li + li {
  margin-left: 60px;
}

.duration-main-block .duration-select-list .form-control {
  width: 220px;
  max-width: 100%;
  font-size: 36px;
  line-height: 40px;
}


.duration-main-block .tnt-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-top: 57px;
}

.duration-main-block .tnt-block li + li {
  margin-left: 58px;
}

.duration-main-block .tnt-block .h2 {
  font-weight: 700;
}

.duration-main-block .tnt-block .h2 span {
  font-weight: 400;
  display: block;
}

.radio-list-output {
  position: relative;
  left: -100%;
  transition: all 0.5s ease-in-out;
}

.radio-list-value .radio-list-output {
  left: 0;
}

/* 3rd stepper css start */

.payment-wrapper {
  margin-top: 60px;
}

.payment-inner {
  width: 848px;
  margin: 0 auto;
  max-width: 100%;
}

.payment-form .exprie-group {
  display: flex;
  justify-content: space-between;
}

.payment-form .dropdown-title {
  color: #448979;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.payment-form .dropdown-inner .btn-primary {
  width: 220px;
}

.payment-form .dropdown-inner .dropdown-menu {
  width: 220px;
  min-width: 220px;
}

.banking-form .form-title {
  margin-bottom: 20px;
}

.banking-form .form-title p {
  font-size: 26px;
  font-weight: 700;
  color: #448979;
  line-height: 40px;
}

.exprie-group .expire-left {
  width: 290px;
  max-width: 100%;
}

.exprie-group .expire-right {
  width: 142px;
  max-width: 100%;
}

.payment-form .action-button {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 15px;
}

/* order-done css start */

.order-wrppaer {
  width: 380px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 80px;
  text-align: center;
}

.order-content p {
  margin-top: 31px;
  font-size: 36px;
  font-weight: 700;
  color: #448979;
  line-height: 43px;
}

.order-wrppaer .action-button {
  text-align: center;
  margin-top: 63px;
  margin-bottom: 20px;
}

.order-wrppaer .action-button .btn-outline-success.secondary {
  width: 444px;
}


/* room department css start */

.room-department-main .room-department-box {
  width: 300px;
  max-width: 100%;
  box-shadow: 0px 3px 6px #7C82921A;
  border: 3px solid #88CCBD;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  padding: 40px 30px;
  margin: 0 auto;
  position: relative;
}

.room-department-main .main-title .sm {
  display: none;
}

.room-department-main div[class^="col"] + div[class^="col"]::before {
  content: '';
  position: absolute;
  top: calc(50% - 15px);
  left: -30px;
  width: 60px;
  height: 31px;
  background-image: url('../images/right-arrow.svg');
  background-size: 103px;
  background-repeat: no-repeat;
  background-position: center;
}

.room-department-main .room-department-box h5 {
  padding-bottom: 30px;
}

.room-department-main .room-department-box ul li {
  padding-bottom: 30px;
}

.room-department-main .room-department-box ul li span {
  display: block;
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  color: #7C8393;
}

.room-department-main .room-department-box ul li span:first-child {
  font-style: italic;
}

.room-department-main .btns-block {
  text-align: center;
  margin-top: 78px;
  margin-bottom: 20px;
}

.room-department-main .btns-block .btn {
  font-size: 30px;
  line-height: 34px;
  background-image: linear-gradient(to right, #448979, #88ccbd);
  background-repeat: no-repeat;
  width: 280px;
  max-width: 100%;
  border: 0;
  transition: all 0.5s;
}

.room-department-main .btns-block .btn:hover {
  background-image: linear-gradient(to right, #306156, #5f8f84);
}

/* tv package css start */

.package-main {
  margin-top: 75px;
}

.package-main .package-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.package-box .box-main {
  width: 50%;
  max-width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 3px solid #88CCBD;
  padding: 30px 54px 40px 30px;
  margin-right: 40px;
}

.package-box .box-main:last-child {
  margin-right: 0;
}

.box-main .box-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.box-title h4 span {
  display: inline;
  font-size: 21px;
  font-style: italic;
  font-weight: 400;
  color: #7C8393;
  line-height: 40px;
  padding-left: 36px;
  letter-spacing: normal;
}

.package-detail {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.package-detail .package-content {
  margin: 10px 44px;
}

.content-inner p {
  font-size: 21px;
  color: #B0B5BE;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
}

.content-inner p span {
  color: #7C8393;
}

.package-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.package-button .btn-outline-success.secondary {
  margin-left: 44px;
}

.dropdown-que p {
  color: #7C8393;
  font-size: 26px;
  font-weight: 400;
  font-style: italic;
  line-height: 40px;
}

.dropdown-inner .btn-primary::after {
  content: none;
}

.dropdown-inner .btn-primary::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  cursor: pointer;
  transition: all 0.5s;
  background-image: url("../images/arrow.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
}

.dropdown-inner .btn-primary {
  width: 280px;
  padding: 24px 27px 24px 43px;
  line-height: normal;
  min-height: 80px;
  border: 3px solid #88CCBD;
  text-align: left;
}

.dropdown-inner .btn-primary:hover {
  background-color: #fff;
}

.dropdown-inner .show .btn-primary {
  border-radius: 10px 10px 0 0;
}

.dropdown-inner .show .btn-primary::before {
  transform: translateY(-50%) rotate(270deg);
}

.dropdown-inner .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  border-color: #88CCBD;
}

.dropdown-inner .btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #fff;
  color: #306156;
  border-color: #88CCBD;
}

.dropdown-inner .btn-group.show {
  display: inline-flex !important;
}

.dropdown-inner .dropdown-menu {
  box-shadow: none;
  border: 0;
  border: 1px solid #88CCBD;
  border-top: 0;
  top: 100px;
  width: 280px;
  min-width: 280px;
  margin: 0;
  border-radius: 0 0 10px 10px;
  padding: 0;
  max-height: 200px;
  overflow-y: auto !important;
}

/* Track */
.dropdown-inner .dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

/* Handle */
.dropdown-inner .dropdown-menu::-webkit-scrollbar-thumb {
  background: #448979;
  border-radius: 5px;
}

.dropdown-menu li:hover a {
  background-color: #88CCBD;
  color: #fff;
}

.dropdown-menu li a {
  display: block;
  padding: 15px 20px;
  font-size: 26px;
  font-weight: 700;
  color: #448979;
}

.dropdown-menu li a:focus {
  background-color: #306156;
  color: #fff;
}

.row-input input {
  width: 280px;
  margin: 0 auto;
}

/* download-invoice css start */

.invoice-main {
  margin-top: 120px;
}

.invoice-main .invoice-inner {
  width: 770px;
  max-width: 100%;
  margin: 0 auto;
}

.invoice-form {
  margin-bottom: 160px;
}

.invoice-form .form-button .btn-secondary {
  height: 60px;
  line-height: 20px;
}

.invoice-inner .action-button {
  text-align: center;
  margin-bottom: 20px;
}

.invoice-inner .action-button .btn-outline-success.secondary {
  width: 444px;
}

/* Login css start */

.login-wrapper {
  padding-top: 60px;
  padding-left: 120px;
}

.login-wrapper .brand {
  width: 106px;
  max-width: 100%;
}

.login-wrapper .login-form {
  padding-top: 70px;
  width: 760px;
  max-width: 100%;
  margin: 0 auto;
}

.login-form.login-error label.label-green {
  color: #A60000;
}

.login-form.login-error .form-control.secondary-input {
  border-color: #A60000;
}

.login-form.login-error .dropdown-title {
  color: #A60000;
}

.login-form.login-error .dropdown-inner .btn-primary {
  border-color: #A60000;
}

.login-form .login-title {
  margin-bottom: 37px;
}

.login-title h1 {
  font-size: 36px;
}

.login-form .sub-title {
  margin-bottom: 40px;
}

.sub-title p {
  font-size: 26px;
  color: #7C8393;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
}

.main-form .login-detail {
  margin-top: 29px;
  margin-bottom: 80px;
}

.login-detail .dropdown-title {
  color: #448979;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
}

.login-detail .dropdown-inner .btn-primary {
  width: 220px;
}

.login-detail .dropdown-inner .dropdown-menu {
  width: 220px;
  min-width: 220px;
}

.login-message p {
  font-style: italic;
  color: #7C8393;
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
}

.login-message p a {
  text-decoration: underline;
  color: #448979;
}

.login-message p a:hover {
  color: #04795d;
}

.login-instruction {
  margin-top: 37px;
}

.login-instruction a {
  font-size: 26px;
  font-style: italic;
  font-weight: 400;
  color: #12008F;
  line-height: 32px;
}

.login-instruction a:hover {
  color: #5e46ff;
}

.login-bottom {
  margin-top: 89px;
  background-color: #FFFFFF;
  padding: 30px 15px;
  text-align: center;
}

/* Confirmation Model CSS Starts */

.modal-wrapper .modal-header {
  border: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #88CCBD;
  padding: 28px 0 27px 50px;
  justify-content: flex-start;
}

.modal-header .mobile-heading {
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: normal;
  color: #fff;
}

.modal-wrapper .modal-body {
  padding: 40px 50px 50px;
}

.modal-body .modal-message {
  border-bottom: 2px solid rgba(124, 131, 147, 0.098);
  padding-bottom: 40px;
}

.modal-message p {
  font-size: 26px;
  line-height: 40px;
  font-style: italic;
  color: #7C8393;
  font-weight: 500;
}

.modal-wrapper .modal-button {
  padding: 0 50px 50px 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-button .btn {
  width: 230px;
  height: 60px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(124, 130, 146, 0.101);
  font-size: 21px;
  line-height: 21px;
  padding: 18px 21px;
}

.confirm-modal .modal-content {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}


/* Confirmation Model CSS Ends */

/* Channel Select Model Starts */

.channel-modal .modal-header {
  border: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #88CCBD;
  padding: 28px 0 27px 50px;
  justify-content: flex-start;
}

.modal-header .mobile-heading {
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: normal;
  color: #fff;
}

.channel-modal .modal-body {
  padding: 40px 50px 50px;
}

.modal-body p {
  font-size: 14px;
  font-weight: 400;
  color: #7C8393;
  line-height: 19px;
}

.modal-body .channel-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  max-height: 300px;
  overflow: hidden auto;
  height: 100%;
}

.channel-box::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.channel-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

/* Handle */
.channel-box::-webkit-scrollbar-thumb {
  background: #448979;
  border-radius: 5px;
}

.channel-box .box-main {
  box-shadow: 0 3px 6px rgba(124, 131, 147, 0.101);
  width: 25%;
  margin-right: 18px;
  padding: 5px;
  margin-bottom: 15px;
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-box .box-main:last-child {
  margin-right: 0;
}

.channel-modal .modal-button {
  padding: 30px 50px 50px 43px;
  text-align: center;
}

.modal-button .btn {
  width: 230px;
  height: 60px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(124, 130, 146, 0.101);
  font-size: 21px;
  line-height: 21px;
  padding: 18px 21px;
}

.channel-modal .modal-content {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

/* Channel Select Model Ends */


/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/

@media only screen and (max-width: 1680px) {
  .wrapper {
    padding: 60px 30px 0 380px;
  }

  .wrapper.subscription-wrapper {
    padding: 60px 440px 0 30px;
  }

  h1 {
    font-size: 25px;
  }

  h3 {
    font-size: 23px;
  }

  h6 {
    font-size: 18px;
  }

  /* sidebar css start */
  aside {
    width: 410px;
  }

  .brand-detail .brand-address {
    font-size: 20px;
  }

  .brand-address p {
    line-height: 30px;
  }

  /* Home css satrt */
  .card-block {
    padding: 0 40px;
  }

  .card-inner .card-wrapper {
    margin-right: 35px;
  }
}

@media only screen and (max-width: 1440px) {

  /* sidebar css start */
  aside {
    width: 350px;
  }

  label {
    font-size: 22px;
  }

  .btn-outline-success.secondary {
    width: 200px;
    font-size: 21px;
  }

  .btn-success {
    width: 200px;
    padding: 17px 15px;
  }

  .subscribe-title p {
    font-size: 20px;
    margin-top: 6px;
  }

  .subscribe-service li {
    margin-bottom: 15px;
  }

  .subscribe-service li .service-label,
  .subscribe-service li .service-value {
    font-size: 18px;
  }

  .subscription-inner .subscribe-total {
    margin-top: 14px;
  }

  .small-title p {
    font-size: 30px;
    margin-top: 6px;
    line-height: 25px;
  }

  .subscription-inner .action-button {
    margin-top: 25px;
  }

  /* subscription css start */
  .wrapper.subscription-wrapper {
    padding: 60px 380px 0 30px;
  }

  .stepper-inner li a {
    width: 220px;
    line-height: 20px;
    font-size: 14px;
    padding: 16px 10px;
  }

  .stepper-inner a .count-number,
  .stepper-inner a .icon-done {
    width: 25px;
    height: 25px;
    line-height: 25px;
    min-width: 25px;
  }

  .stepper-inner a .icon-done {
    line-height: 29px;
  }

  .stepper-inner a .name {
    margin-left: 10px;
  }

  /* 3rd stepper css start */
  .payment-inner {
    width: 750px;
  }

  .order-wrppaer {
    margin-top: 63px;
  }

  .order-content p {
    margin-top: 20px;
  }

  .package-box .box-main {
    padding: 30px;
    margin-right: 25px;
  }

  .package-button .btn-outline-success.secondary {
    margin-left: 20px;
  }

  .package-detail .package-content {
    margin: 10px 24px;
  }

  .content-inner p {
    font-size: 18px;
  }


  /* room department css start */
  .room-department-main .btns-block .btn {
    font-size: 20px;
    line-height: 24px;
    width: 200px;
    height: 70px;
  }

  /* Confirmation CSS starts */
  .confirm-modal .modal-content {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
  }

  .modal-wrappe .modal-header {
    padding: 15px 0 15px 20px;
  }

  .modal-wrapper .modal-body {
    padding: 25px 20px 25px;
  }

  .modal-body .modal-message {
    padding-bottom: 25px;
  }

  .modal-wrapper .modal-button {
    padding: 0 15px 26px 15px;
  }

  /* Channel Select CSS Starts */
  .modal-wrappe .modal-header {
    padding: 15px 0 15px 20px;
  }

  .channel-modal .modal-body {
    padding: 25px 20px;
  }

  .modal-body .modal-message {
    padding-bottom: 25px;
  }

  .channel-modal .modal-button {
    padding: 26px 15px;
  }


}


@media only screen and (max-width: 1199px) {
  .wrapper {
    padding: 30px 20px 0 280px;
  }

  h1 {
    font-size: 25px;
  }

  h4 {
    font-size: 25px;
  }

  .form-control {
    height: 60px;
    font-size: 20px;
  }

  .card {
    padding: 30px 0;
  }

  .btn-secondary {
    width: 200px;
    height: 60px;
    font-size: 22px;
    line-height: 22px;
  }

  .btn-success {
    padding: 15px 10px;
    height: 50px;
    line-height: 15px;
    font-size: 16px;
  }

  .btn-outline-success.secondary {
    height: 50px;
    font-size: 16px;
    line-height: 15px;
  }

  /* sidebar css start */
  aside {
    width: 300px;
    padding-top: 80px;
  }

  .sidebar-main .brand {
    margin-bottom: 72px;
  }

  .brand-detail .brand-title {
    margin-bottom: 38px;
  }

  .brand-detail .brand-address {
    font-size: 18px;
  }

  /* Home CSS start */
  .card-inner .card-wrapper {
    margin-right: 20px;
  }

  /* subscription css start */
  .wrapper.subscription-wrapper {
    padding: 40px 310px 0 30px;
  }

  .subscribe-title p {
    font-size: 18px;
    margin-top: 3px;
  }

  .subscribe-service li {
    margin-bottom: 10px;
  }

  .subscribe-service li .service-label,
  .subscribe-service li .service-value {
    font-size: 15px;
  }

  .subscription-inner .subscribe-total {
    margin-top: 9px;
  }

  .subscription-inner .action-button {
    margin-top: 20px;
  }

  .stepper-inner li a {
    width: 180px;
  }

  .subscribe-left .brand {
    width: 100px;
    margin-right: 20px;
  }

  .stepper-inner {
    padding: 0 15px;
  }

  .subscription-main {
    margin-top: 30px;
  }

  .main-title {
    margin-bottom: 28px;
  }

  .main-title p {
    font-size: 20px;
  }

  /* 2nd stepper css start */
  .duration-main-block .duration-select-list li + li {
    margin-left: 20px;
  }

  /* 3rd stepper css start */
  .payment-wrapper {
    margin-top: 40px;
  }

  /* tv pakage css start */
  .package-main .package-box {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .package-box .box-main {
    margin-right: 1px;
    margin-bottom: 26px;
    padding: 20px 16px;
  }

  .package-box .box-main:last-child {
    margin-bottom: 0;
  }

  .box-title h4 span {
    padding-left: 20px;
    font-size: 14px;
  }

  .package-detail {
    margin-bottom: 20px;
  }

  .content-inner p {
    font-size: 16px;
  }

  /* order-complete css start */
  .order-content p {
    font-size: 26px;
    line-height: 32px;
  }

  .order-wrppaer .action-button {
    margin-top: 40px;
  }

  .order-wrppaer .action-button .btn-outline-success.secondary {
    width: 100%;
  }

  /* room department css start */
  .room-department-main .room-department-box {
    width: 270px;
    padding: 40px 20px;
  }

  .dropdown-que p {
    font-size: 20px;
    line-height: 25px;
  }

  .dropdown-inner .btn-primary {
    width: 200px;
    min-height: 60px;
    padding: 15px;
  }

  .dropdown-inner .dropdown-menu {
    width: 200px;
    min-width: 200px;
  }

  .dropdown-menu li a {
    font-size: 20px;
    padding: 10px 20px;
  }

  .row-input input {
    width: 200px;
  }

  .dropdown-que p {
    font-size: 18px;
  }

  .custom-radio .custom-radio-layout {
    width: 70px;
    height: 70px;
    font-size: 30px;
    line-height: 34px;
  }

  /* download-invoice css start */
  .invoice-main {
    margin-top: 40px;
  }

  .invoice-main .invoice-inner {
    width: 600px;
  }

  .invoice-form {
    margin-bottom: 77px;
  }

  /* login css start */
  .login-wrapper {
    padding-top: 40px;
  }

  .login-wrapper .login-form {
    padding-top: 40px;
  }

  .login-form .login-title {
    margin-bottom: 20px;
  }

  .login-form .sub-title {
    margin-bottom: 25px;
  }

  .main-form .login-detail {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .login-instruction {
    margin-top: 25px;
  }

  .login-bottom {
    margin-top: 50px;
  }

}

@media only screen and (max-width: 1024px) {

  h2,
  .h2 {
    font-size: 22px;
  }

  .wrapper.wrapper.has-padding {
    padding: 60px 60px 0 60px;
  }

  /* Home css start */
  .home-title {
    margin-top: 15px;
  }

  .home-title p {
    margin-top: 10px;
    font-size: 18px;
  }

  .card-block {
    margin-top: 35px;
  }


  .card-label p {
    font-size: 22px;
  }


}

@media only screen and (max-width: 991px) {

  h2,
  .h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .wrapper {
    padding: 40px 36px;
  }

  .wrapper.wrapper.has-padding {
    padding: 40px 36px;
  }

  h3 {
    font-size: 21px;
  }

  h5,
  .h5 {
    font-size: 20px;
    line-height: 24px;
  }

  .custom-radio .custom-radio-layout {
    width: 80px;
    height: 60px;
    font-size: 30px;
    line-height: 34px;
  }

  /* sidebar css start */
  aside {
    opacity: 0;
    visibility: hidden;
    width: 240px;
    padding-top: 40px;
  }

  .sidebar-main {
    align-items: flex-start;
    text-align: left;
  }

  .sidebar-main .brand {
    width: 87px;
    margin-left: 30px;
  }

  .brand-address .text-strong {
    margin-bottom: 17px;
  }

  /* Mobile Menu CSS start */
  .mobile-header {
    display: block;
  }

  /* Home css start */
  .has-padding .brand {
    width: 100px;
    margin-bottom: 22px;
  }

  .home-block .home-box {
    display: none;
  }

  .home-title {
    margin-top: 0;
  }

  .home-title p {
    margin-top: 0;
  }

  .card-block {
    padding: 0;
  }

  .card-block .card-inner {
    flex-direction: column;
  }


  .card-inner .card-wrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .card-inner .card-wrapper:last-child {
    margin-bottom: 0;
  }

  .card-label {
    margin-bottom: 12px;
    min-height: auto;
    height: 3rem;
  }

  .card-label p {
    font-size: 16px;
    line-height: 20px;
  }

  .card-wrapper .card {
    flex-direction: row;
    align-items: center;
    text-align: left;
  }

  .card-wrapper .card img {
    margin: 0;
  }

  .card-wrapper .card-body {
    margin-top: 0;
    margin-left: 17px;
  }


  .card-wrapper .card img {
    max-width: 90px;
  }

  /* subscription css start */
  .wrapper.subscription-wrapper {
    padding: 40px 36px 0;
  }

  .mobile-block {
    display: block;
  }

  .subscription-inner .subscribe-title {
    text-align: left;
  }

  .subscribe-title p {
    margin-top: 20px;
  }

  .subscribe-left .brand {
    width: 86px;
  }

  .subscription-inner .subscribe-total {
    text-align: left;
  }

  .subscription-inner .action-button {
    display: none;
  }

  .subscribe-right .page-back {
    display: none;
  }

  .subscribe-right .subscribe-menu {
    display: block;
  }

  .subscrib-hello {
    display: none;
  }

  .stepper-main {
    margin-top: 30px;
  }

  .duration-main-block .duration-select-list {
    margin-bottom: 40px;
  }

  .duration-main-block .duration-select-list .form-control {
    width: 220px;
    font-size: 26px;
    line-height: 30px;
  }

  .order-content p {
    font-size: 26px;
    line-height: 32px;
  }

  /* tv pakage css start */
  .package-main {
    margin-bottom: 30px;
  }

  /* room department css start */
  .room-department-main .room-department-box {
    width: 270px;
    padding: 20px;
  }

  .room-department-main .room-department-box ul li span {
    font-size: 20px;
    line-height: 24px;
  }

  .room-department-main .room-department-box ul li {
    padding-bottom: 18px;
  }

  /* Login css start */
  .login-wrapper {
    padding-left: 36px;
  }

  /* Confimation CSS Starts */
  .modal-wrappe .modal-header {
    justify-content: center;
  }

  .modal-body .modal-message {
    border: 0;
    padding-bottom: 0;
    text-align: center;
  }

  .modal-message p {
    font-size: 18px;
    line-height: 26px;
  }

  .modal-wrapper .modal-button {
    flex-direction: column;
  }

  .modal-button .btn {
    margin-top: 30px;
  }

  .modal-button .btn:first-child {
    margin-top: 0;
  }

  /* Channel Select CSS Starts */
  .modal-wrappe .modal-header {
    justify-content: center;
  }

  .modal-body .modal-message {
    border: 0;
    padding-bottom: 0;
    text-align: center;
  }

  .modal-message p {
    font-size: 18px;
    line-height: 26px;
  }

  .modal-body p {
    font-size: 10px;
  }

}

@media only screen and (max-width: 767px) {


  .card {
    padding: 13px 42px 15px 28px;
  }

  label {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .form-control.secondary-input {
    padding: 20px 15px;
    height: 40px;
  }

  h2,
  .h2 {
    font-size: 18px;
    line-height: 22px;
  }

  h4 {
    font-size: 16px;
  }

  .form-row > .col,
  .form-row > [class*=col-] {
    padding-right: 5px;
  }

  .btn-primary {
    font-size: 16px;
  }

  .btn-primary.secondary {
    border: none;
    background-image: linear-gradient(to right, #448979, #88ccbd);
  }

  .btn-primary.arrow-right::after {
    right: 22px;
  }

  .btn-primary.secondary.arrow-right::after {
    right: 22px;
  }

  .btn-success {
    height: 41px;
    border-radius: 5px;
    line-height: 14px;
    padding: 6px 10px;
    width: 119px;
  }

  .btn-outline-success.secondary {
    height: 41px;
    line-height: 14px;
    border-radius: 5px;
    padding: 6px 10px;
    width: 119px;
  }

  .form-control {
    height: 55px;
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  /* Home CSS Starts */
  .home-title {
    display: block;
  }


  /* subscription css start */
  .stepper-main {
    margin: 30px -36px 0;
  }

  .stepper-inner {
    overflow: hidden;
    padding: 0;
  }

  .stepper-inner ul {
    flex-wrap: nowrap;
    width: 300%;
    transition: all 0.5s;
    margin: 0;
  }

  .stepper-inner ul.second-slide {
    transform: translateX(-33.33%);
  }

  .stepper-inner ul.third-slide {
    transform: translateX(-66.67%);
  }

  .stepper-inner li {
    width: 100%;
    padding: 0;
  }

  .stepper-inner li + li {
    margin-left: 0;
  }

  .stepper-inner ul.second-slide li {
    width: 33.33%;
  }

  .stepper-inner ul.third-slide li {
    width: 66.67%;
  }

  .stepper-inner li::after {
    content: none;
  }

  .stepper-inner li .action-arrow {
    display: block;
  }

  .stepper-inner li a {
    width: calc(100% - 160px);
    margin: 0 auto;
    display: flex;
    padding: 26px 10px;
    font-size: 17px;
  }

  .stepper-inner a .count-number,
  .stepper-inner a .icon-done {
    width: 48px;
    height: 48px;
    line-height: 48px;
    min-width: 48px;
  }


  .stepper-inner.secondary li {
    width: 33.33%;
    padding: 0;
  }

  .subscription-main {
    margin-top: 20px;
  }

  .main-title {
    margin-bottom: 20px;
  }

  .main-title p {
    font-size: 18px;
  }

  /* 2nd stepper css start */
  .duration-main-block .duration-select-list {
    width: 182px;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 0 auto 20px;
  }

  .duration-main-block .duration-select-list .form-control {
    width: 180px;
  }

  .duration-main-block .duration-select-list li {
    padding: 0 0px 20px;
    margin: 0 !important;
  }

  .duration-main-block .duration-select-list.radio-list li {
    width: 50%;
  }

  .duration-main-block .duration-select-list.radio-list li.input {
    width: 100%;
  }

  .duration-main-block .tnt-block {
    display: none;
  }

  .radio-list-output {
    left: 0;
  }


  /* 3rd stepper css start */
  .payment-wrapper {
    margin-top: 22px;
  }

  .payment-form .dropdown-title {
    font-size: 18px;
  }

  .payment-form .btn-group {
    width: 100%;
  }

  .payment-form .dropdown-inner .btn-primary {
    width: 100%;
    height: 30px;
  }

  .payment-form .dropdown-inner .dropdown-menu {
    width: 100%;
    min-width: 100%;
  }

  .banking-form .form-title {
    margin-bottom: 10px;
  }

  .banking-form .form-title p {
    font-size: 18px;
  }

  .payment-form .exprie-group {
    flex-wrap: wrap;
  }

  .exprie-group .expire-left {
    width: 100%;
  }

  .payment-form .action-button {
    margin-top: 25px;
  }

  /* tv pakage css start */
  .package-main {
    margin-top: 20px;
  }

  .package-box .box-main {
    width: 100%;
    margin-right: 0;
    padding: 15px 16px 22px;
  }

  .box-main .box-title {
    margin-bottom: 11px;
  }

  .package-detail {
    margin-bottom: 16px;
  }

  .content-inner p {
    font-size: 12px;
    line-height: 20px;
  }

  .package-button .btn-outline-success.secondary {
    margin-left: 23px;
  }


  /* room department css start */
  .room-department-main .room-department-box {
    width: 320px;
    border: 0;
  }

  .room-department-main div[class^="col"]:last-child .room-department-box {
    background-color: #EEF7F4;
  }

  .room-department-main div[class^="col"] + div[class^="col"]::before {
    display: none;
  }

  .room-department-main .main-title .sm {
    display: block;
  }

  .room-department-main .btn-primary {
    background-color: #fff;
    border: 1px solid #448979;
    width: 300px;
    max-width: 100%;
  }

  .room-department-main .btns-block {
    width: calc(100% + 72px);
    max-width: calc(100% + 72px);
    margin: 0 -36px;
    padding: 20px;
    box-shadow: 0px -5px 6pt #FFFFFF;
  }

  .room-department-main .btns-block .btn {
    width: 300px;
    max-width: 100%;
  }

  .dropdown-inner .btn-primary {
    width: 180px;
    min-height: 55px;
  }

  .dropdown-inner .btn-primary::before {
    right: 15px;
  }

  .row-input input {
    width: 180px;
  }

  .dropdown-inner .dropdown-menu {
    top: 60px;
    width: 180px;
    min-width: 180px;
  }

  .dropdown-menu li a {
    font-size: 16px;
    padding: 8px 20px;
  }

  /* download-invoice css start */
  .invoice-main .invoice-inner {
    width: 100%;
  }

  .invoice-inner .action-button .btn-outline-success.secondary {
    width: 100%;
  }

  .invoice-form .form-button .btn-secondary {
    width: 100%;
  }

  /* Login css start */
  .login-wrapper {
    padding: 40px 36px 0;
  }

  .main-form .login-detail {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .login-wrapper .login-form {
    padding-top: 17px;
    width: 100%;
  }

  .login-form .login-title {
    margin-bottom: 10px;
  }

  .login-title h1 {
    font-size: 18px;
  }

  .login-form .sub-title {
    margin-bottom: 15px;
  }

  .sub-title p {
    font-size: 16px;
    line-height: 20px;
  }

  .login-detail .dropdown-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .login-detail .dropdown-inner .btn-primary {
    width: 100%;
    height: 40px;
  }

  .login-detail .btn-group {
    width: 100%;
  }

  .login-detail .dropdown-inner .dropdown-menu {
    width: 100%;
    min-width: 100%;
  }

  .login-message p {
    font-size: 18px;
    line-height: 20px;
  }

  .login-instruction {
    margin-top: 15px;
  }

  .login-instruction a {
    font-size: 14px;
    line-height: 20px;
  }

  .login-bottom {
    margin-top: 30px;
  }


}

@media only screen and (max-width: 575px) {

  .stepper-inner li a {
    width: calc(100% - 100px);
  }

  .stepper-inner li .action-arrow {
    width: 50px;
  }

  /* 3rd stepper css start */
  .payment-inner {
    width: 100%;
  }

  .payment-form {
    margin-bottom: 20px;
  }

  .payment-form .exprie-group {
    flex-direction: column;
  }

  .exprie-group .expire-left,
  .exprie-group .expire-right {
    width: 100%;
  }

  .order-wrppaer {
    width: 100%;
  }

  /* room department css start */
  .room-department-main .room-department-box {
    width: calc(100% + 72px);
    max-width: calc(100% + 72px);
    margin: 0 -36px;
  }

  .room-department-main div[class^="col"] + div[class^="col"]::before {
    display: none;
  }

}
