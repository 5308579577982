$humehealth-main-white: rgba(255, 255, 255, 1);
$humehealth-main-green: rgba(68, 137, 121, 1);
$humehealth-second-green: rgba(136, 204, 189, 1);
$humehealth-light-green: rgba(231, 245, 242, 0.36);
$humehealth-main-gray: rgba(124, 131, 147, 1);
$humehealth-light-gray: rgba(124, 130, 146, 0.1);
$humehealth-second-gray: rgba(124, 131, 147, 0.6);
$humehealth-error: darkred;
@mixin phone {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin kiosk {
  @media only screen and (min-width: 767px) and (max-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == phone) {
      @include phone {
        @content;
      }
    } @else if ($key == kiosk) {
      @include kiosk {
        @content;
      }
    }
  }
}

@mixin default-text($weight: bold) {
  color: $humehealth-main-green;
  font-weight: $weight;
  font-size: 1.625rem;

  @include media(phone) {
    font-size: 1rem;
  }
}

@mixin base-button {
  min-width: 60px;
  height: 60px;
  font-weight: bold;
  font-size: 1.3125rem;
  transition: all 0.5s;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 3px 6px $humehealth-light-gray;
  text-transform: uppercase;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #448979;
    border-radius: 5px;
  }
}

@mixin humehealth-panels {
  display: grid;
  grid-template-columns: 1fr 480px;
  grid-template-rows: 180px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "header sidebar"
    "content sidebar";

  @include media(phone) {
    grid-template-columns: 100%;
    grid-template-rows: 100px 1fr;
    grid-template-areas:
      "header"
      "content";
  }

  @include media(kiosk) {
    grid-template-columns: 100%;
    grid-template-rows: 280px 340px 1fr;
    grid-template-areas:
      "header"
      "sidebar"
      "content";
  }

  &__header {
    grid-area: header;
    padding: 60px 60px 0;

    @include media(phone) {
      padding: 40px 36px 0;
    }
  }

  &__content {
    grid-area: content;
    align-self: start;
    padding: 0 60px;

    @include media(phone) {
      padding: 0 36px;
    }
  }

  &__sidebar {
    grid-area: sidebar;
  }
}

.humehealth {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 48px 120px;

    @include media(phone) {
      padding: 40px 36px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo {
      width: 106px;
      max-width: 100%;
    }

    &__action {
      display: none;

      @include media(phone) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > * {
          margin: 20px 16px;
        }
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    max-width: 100%;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 37px;
    color: $humehealth-main-green;
    font-weight: bold;
    font-size: 2.25rem;

    @include media(phone) {
      margin-bottom: 10px;
      font-size: 1.3rem;
    }
  }

  &__sub-title {
    margin-bottom: 40px;
    color: $humehealth-main-gray;
    font-style: italic;
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 40px;

    @include media(phone) {
      margin-bottom: 15px;
      font-size: 1rem;
      line-height: 25px;
    }
  }

  &__label {
    @include default-text();
    margin-bottom: 15px;

    @include media(phone) {
      margin-bottom: 10px;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;

    @include media(phone) {
      padding-bottom: 30px;
    }
  }

  &__form-field {
    padding: 0 40px 30px 0;

    @include media(phone) {
      padding-right: inherit;
    }
  }

  &__button-primary {
    @include base-button();
    width: 230px;
    color: $humehealth-main-white;
    background: linear-gradient(118deg, $humehealth-main-green 50%, $humehealth-second-green);

    &:focus,
    &:active,
    &:hover {
      background: linear-gradient(118deg, darken($humehealth-main-green, 10%) 50%, darken($humehealth-second-green, 10%));
    }

    &:disabled {
      background: linear-gradient(118deg, desaturate($humehealth-main-green, 30%) 50%, desaturate($humehealth-second-green, 30%));
    }
  }

  &__button-secondary {
    @include base-button();
    color: $humehealth-main-green;
    background: $humehealth-main-white;

    &:focus,
    &:active,
    &:hover {
      background: darken($humehealth-main-white, 10%);
    }

    &:disabled {
      background: desaturate($humehealth-main-white, 30%);
    }
  }

  &__input {
    border: 0;
    border-bottom: 3px solid $humehealth-second-green;
    box-shadow: 0 3px 6px lighten($humehealth-main-gray, 40%);
    border-radius: 0;
    text-align: left;
    padding: 26px 27px;
    width: 360px;
    max-width: 100%;
    height: 80px;
    display: block;
    background-color: $humehealth-main-white;
    font-weight: 700;

    @include default-text();

    @include media(phone) {
      width: 100%;
      height: 60px;
    }
  }

  &__footer {
    background-color: $humehealth-main-white;
    padding: 30px 15px;
    text-align: center;
  }
}

.humehealth-input-datepicker {
  display: flex;
  align-items: center;
}

app-keyboard-input {
  z-index: 9;
}

::ng-deep mat-keyboard-container {
  min-width: 100% !important;
  z-index: 10;
}

::ng-deep mat-keyboard-key {
  height: 5rem;
}

